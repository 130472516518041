<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Thermochemistry of Salts Experiment: Results</h2>

      <p class="mb-5">
        a) Enter all of the data collected during the simulation in the table below. Note that the
        heat capacity of both the water inside the can and the solution inside the cooling jacket
        can be assumed to be the same as water, i.e.
        <stemble-latex content="$\text{4.184 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}.$" />
        The heat capacity of the aluminum can can be assumed to be
        <stemble-latex content="$\text{0.900 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}.$" />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td style="width: 16%">
              <stemble-latex content="$\text{Variable}$" />
            </td>
            <td style="text-align: center; width: 21%">
              <stemble-latex :content="salt1" />
            </td>
            <td style="text-align: center; width: 21%">
              <stemble-latex :content="salt2" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Mass of can (g)</td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCan1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCan2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of water inside can (g)</td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCanWater1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCanWater2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of salt (g)</td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massSalt1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massSalt2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of water in cooling jacket (g)</td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massJacketWater1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massJacketWater2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{T}_\text{i}~(^\circ\text{C})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ti1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ti2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{T}_\text{f}~(^\circ\text{C})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Tf1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Tf2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{T}~(^\circ\text{C})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaT1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaT2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}_\text{rxn,exp}~\text{(J)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxnJ1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxnJ2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}_\text{soln,exp}~\text{(J/mol)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxn1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxn2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="width: 16%" class="py-2 my-2">
              <stemble-latex content="$\Delta\text{H}_\text{soln,lit}~\text{(J/mol)}$" />
            </td>
            <td style="text-align: center; width: 21%" class="py-2 my-2">
              <stemble-latex :content="enthalpySalt1" />
            </td>
            <td style="text-align: center; width: 21%" class="py-2 my-2">
              <stemble-latex :content="enthalpySalt2" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\%\,\text{Error}$" />
              <stemble-latex content="$\,(\%)$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2 mt-5">
        b) Please upload your temperature vs time graphs AND your data sheet for this lab using the
        upload field below.
      </p>

      <p class="mb-3">
        <v-file-input v-model="attachments" multiple accept="application/pdf" />
      </p>

      <p class="mb-1" style="font-weight: bold">
        Have multiple files to upload? Click
        <a
          href="https://support.stemble.ca/attach-multiple-files "
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        to find out how.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'ChemMcMPA4_ResultsV2',
  components: {
    STextarea,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massCan1: null,
        massCan2: null,
        massCanWater1: null,
        massCanWater2: null,
        massSalt1: null,
        massSalt2: null,
        massJacketWater1: null,
        massJacketWater2: null,
        Ti1: null,
        Ti2: null,
        Tf1: null,
        Tf2: null,
        deltaT1: null,
        deltaT2: null,
        deltaHrxnJ1: null,
        deltaHrxnJ2: null,
        deltaHrxn1: null,
        deltaHrxn2: null,
        pctError1: null,
        pctError2: null,
      },
      attachments: [],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content ?? '6';
    },
    // KBr, NH4Br;
    // KBr, KNO3;
    // NH4Br, KBr;
    // NH4Br, KNO3;
    // KNO3, KBr;
    // KNO3, NH4Br
    salt1() {
      if (this.versionNumber.value === 1 || this.versionNumber.value === 2) {
        return '$\\ce{KBr}$';
      } else if (this.versionNumber.value === 3 || this.versionNumber.value === 4) {
        return '$\\ce{NH4Br}$';
      } else if (this.versionNumber.value === 5 || this.versionNumber.value === 6) {
        return '$\\ce{KNO3}$';
      } else {
        return '$\\ce{KNO3}$';
      }
    },
    salt2() {
      if (this.versionNumber.value === 3 || this.versionNumber.value === 5) {
        return '$\\ce{KBr}$';
      } else if (this.versionNumber.value === 1 || this.versionNumber.value === 6) {
        return '$\\ce{NH4Br}$';
      } else if (this.versionNumber.value === 2 || this.versionNumber.value === 4) {
        return '$\\ce{KNO3}$';
      } else {
        return '$\\ce{NH4Br}$';
      }
    },
    enthalpySalt1() {
      if (this.versionNumber.value === 1 || this.versionNumber.value === 2) {
        return '$19900~\\text{J/mol}$';
      } else if (this.versionNumber.value === 3 || this.versionNumber.value === 4) {
        return '$16800~\\text{J/mol}$';
      } else if (this.versionNumber.value === 5 || this.versionNumber.value === 6) {
        return '$34900~\\text{J/mol}$';
      } else {
        return '$34900~\\text{J/mol}$';
      }
    },
    enthalpySalt2() {
      if (this.versionNumber.value === 3 || this.versionNumber.value === 5) {
        return '$19900~\\text{J/mol}$';
      } else if (this.versionNumber.value === 1 || this.versionNumber.value === 6) {
        return '$16800~\\text{J/mol}$';
      } else if (this.versionNumber.value === 2 || this.versionNumber.value === 4) {
        return '$34900~\\text{J/mol}$';
      } else {
        return '$16800~\\text{J/mol}$';
      }
    },
  },
};
</script>
<style scoped></style>
